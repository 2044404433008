import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container, Apex } from './header.css';

const Header = ({ children, tag, apex, ...props }) => {
  return (
    <Wrapper>
      <Container {...props}>
        {tag === 'h1' && (
          <h1>
            {children}
            <Apex>{apex}</Apex>
          </h1>
        )}
        {tag === 'h2' && (
          <h2>
            {children}
            <Apex>{apex}</Apex>
          </h2>
        )}
        {tag === 'h3' && (
          <h3>
            {children}
            <Apex>{apex}</Apex>
          </h3>
        )}
        {tag === 'h4' && (
          <h4>
            {children}
            <Apex>{apex}</Apex>
          </h4>
        )}
        {tag === 'h5' && (
          <h5>
            {children}
            <Apex>{apex}</Apex>
          </h5>
        )}
        {!tag && <h3>{children}</h3>}
      </Container>
    </Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.string.isRequired,
  tag: PropTypes.string,
  apex: PropTypes.number,
};

Header.defaultProps = {
  tag: null,
  apex: null,
};

export default Header;
