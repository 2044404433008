import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: ${props => props.theme.spacing.section};
  padding-bottom: calc(${props => props.theme.spacing.padding} * 2.5);
  ${MEDIA.MAX_MEDIUM`
    padding-top: calc(${props => props.theme.spacing.section} / 2);
    padding-bottom: calc(${props => props.theme.spacing.padding} * 1.5);
  `};
`;

export const Container = styled.div`
  h1,
  h2,
  h3 {
    position: relative;
    display: inline-block;
  }
`;

export const Apex = styled.span`
  color: ${props => props.theme.colors.primary};
  opacity: 0.5;
  vertical-align: super;
  margin-left: 5px;
`;
